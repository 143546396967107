html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
  font-family: 'Helvetica';
}
body,
html {
  font-size: 10px;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}
*:active,
*:focus {
  outline: 0;
}
b,
strong {
  font-weight: 700;
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
a:link,
a:visited,
a:active {
  text-decoration: none !important;
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}
@font-face {
  font-family: 'Zona Pro Bold';
  src: url("../fonts/ZonaPro-Bold.otf") format('opentype');
  src: url("../fonts/ZonaPro-Bold.eot") format('embedded-opentype');
  src: url("../fonts/ZonaPro-Bold.WOFF") format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Zona Pro Regular';
  src: url("../fonts/ZonaPro.otf") format('opentype');
  src: url("../fonts/ZonaPro.eot") format('embedded-opentype');
  src: url("../fonts/ZonaPro.WOFF") format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/HelveticaLTStd-Roman.otf") format('opentype');
  src: url("../fonts/HelveticaLTStd-Roman.eot") format('embedded-opentype');
  src: url("../fonts/HelveticaLTStd-Roman.WOFF") format('woff');
  font-weight: 400;
  font-style: normal;
}
.section-title,
.section-subtitle {
  font-family: 'Zona Pro Bold';
}
@media screen and (max-width: 1025px) {
  .section-title,
  .section-subtitle {
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
}
@media screen and (min-width: 1025px) {
  .section-title,
  .section-subtitle {
    font-size: 4rem;
    line-height: 4.4rem;
  }
}
.section-title.white,
.section-subtitle.white {
  color: #fff;
}
.section-title.red,
.section-subtitle.red {
  color: #c4161c;
}
@media screen and (max-width: 1025px) {
  .section-title.default-margin,
  .section-subtitle.default-margin {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1025px) {
  .section-title.default-margin,
  .section-subtitle.default-margin {
    margin-bottom: 6rem;
  }
}
.section-title.smaller-margin,
.section-subtitle.smaller-margin {
  margin-bottom: 4rem;
}
.section-title a,
.section-subtitle a {
  color: inherit;
}
.section-subtitle {
  font-size: 2.4rem;
  line-height: 2.4rem;
}
@media screen and (max-width: 1025px) {
  .section-subtitle.default-margin {
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 1025px) {
  .section-subtitle.default-margin {
    margin-bottom: 6rem;
  }
}
.section-subtitle.smaller-margin {
  margin-bottom: 3rem;
}
.section-subtitle.red {
  color: #c4161c;
}
.generic-text,
.main-contact-form .field-description,
.wp-wrap p {
  font-family: 'Helvetica';
}
@media screen and (max-width: 1025px) {
  .generic-text,
  .main-contact-form .field-description,
  .wp-wrap p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text,
  .main-contact-form .field-description,
  .wp-wrap p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
.generic-text a,
.main-contact-form .field-description a,
.wp-wrap p a {
  color: inherit;
}
.generic-text.gray,
.main-contact-form .field-description.gray,
.wp-wrap p.gray {
  color: #6d6e71;
}
.generic-text.white,
.main-contact-form .field-description.white,
.wp-wrap p.white {
  color: #fff;
}
.generic-text.is-paragraph,
.main-contact-form .field-description.is-paragraph,
.wp-wrap p.is-paragraph {
  margin-bottom: 2rem;
}
.generic-text.smaller,
.main-contact-form .field-description.smaller,
.wp-wrap p.smaller {
  font-size: 1.4rem;
}
.generic-text.slightly-bigger,
.main-contact-form .field-description.slightly-bigger,
.wp-wrap p.slightly-bigger {
  font-size: 1.8rem;
}
.generic-text.smallest,
.main-contact-form .field-description.smallest,
.wp-wrap p.smallest {
  font-size: 1.2rem;
}
.generic-text.bigger-paragraph,
.main-contact-form .field-description.bigger-paragraph,
.wp-wrap p.bigger-paragraph {
  margin-bottom: 6.5rem;
}
.t-red {
  color: #c4161c;
}
.btn-default {
  font-family: 'Zona Pro Bold';
  font-size: 2rem;
}
.btn-default.red-and-black {
  border: 0.5rem solid #c4161c;
  background-color: rgba(0,0,0,0.75);
  color: #fff;
  text-align: center;
}
.btn-default.red-and-black:hover {
  color: #c4161c;
  background-color: rgba(255,255,255,0.75);
}
.btn-default.full-red {
  border: 0.1rem solid #c4161c;
  background-color: #c4161c;
  text-align: center;
  color: #fff;
}
.btn-default.full-red:hover {
  border-color: #000;
  background-color: #000;
}
.submit-contact {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 4.7rem;
}
@media screen and (max-width: 1025px) {
  .submit-contact {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .submit-contact {
    width: 36rem;
    margin-top: 5rem;
  }
}
.go-to-product-page,
.go-to-blog-post,
.go-to-workplace-spot {
  width: 100%;
  height: 3.5rem;
  display: block;
  padding-top: 0.5rem;
  background-color: #e30613;
}
.cta-we-call {
  transform: skewX(-35deg);
  float: right;
  outline: 0.8rem solid rgba(255,255,255,0.2);
  height: 5rem;
  width: 27rem;
}
.cta-we-call .unskew {
  transform: skewX(35deg);
}
@media screen and (max-width: 1025px) {
  .cta-we-call {
    display: none;
  }
}
.fixed-header-cta {
  width: 19rem;
  height: 3.6rem;
  font-size: 1.6rem;
  margin-top: 0.7rem;
  border: 0.2rem solid transparent;
}
.fixed-header-cta:hover {
  background-color: #fff !important;
  color: #c4161c !important;
  border-color: #c4161c !important;
}
.fixed-header-cta:hover i {
  color: #c4161c !important;
}
.modal-submit {
  width: 12.8rem;
  border: 0.2rem solid #fff;
  height: 4rem;
  font-family: 'Zona Pro Bold';
  font-size: 1.8rem;
  color: #fff;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1025px) {
  .modal-submit {
    background-color: #c4161c;
  }
}
@media screen and (min-width: 1025px) {
  .modal-submit {
    background-color: #000;
  }
}
.modal-submit:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;
}
.close-modal {
  position: absolute;
  border: 0;
  background-color: transparent;
  font-size: 4.8rem;
}
@media screen and (max-width: 1025px) {
  .close-modal {
    color: #c4161c;
    top: -6rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 3rem;
  }
}
@media screen and (min-width: 1025px) {
  .close-modal {
    top: 1.3rem;
    left: 2rem;
    color: #fff;
    transform: rotate(-45deg);
  }
}
.close-modal:hover {
  color: #000;
  transform: rotate(-45deg) scale(1.1);
}
.return-submenu {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 15.7rem;
  height: 59px;
  width: 100%;
  border: 0;
  background-color: #090908;
  color: #fff;
  text-align: left;
  font-family: 'Zona Pro Bold';
  font-size: 1.3rem;
  padding-left: 1.5rem;
  transition: opacity 0.2s ease-in-out;
}
.return-submenu:not(.is-active) {
  transition: none;
  opacity: 0;
  pointer-events: none;
}
@media screen and (min-width: 1025px) {
  .return-submenu {
    display: none;
  }
}
body:not(.loaded) {
  overflow: hidden;
}
body:not(.loaded) .modal-background {
  display: none;
}
body.loaded .loader-wrapper {
  display: none;
}
.center-content {
  width: 100%;
  margin: auto;
  max-width: 120rem;
  z-index: 2;
}
.center-content:not(.not-relative) {
  position: relative;
}
@media screen and (max-width: 1025px) {
  .center-content:not(.nopadding) {
    padding: 0 1.5rem;
  }
}
.dib {
  display: inline-block;
  vertical-align: top;
}
@media screen and (min-width: 1025px) {
  .full {
    display: block;
    width: 100%;
    clear: both;
  }
}
@media screen and (min-width: 1025px) {
  .half {
    float: left;
    width: 47.5%;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .half + .half:not(:nth-of-type(2n+3)) {
    margin-left: 5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-third {
    float: left;
    width: 31.666%;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .one-third + .one-third:not(:nth-of-type(3n+4)) {
    margin-left: 2.5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-third-child {
    float: left;
    width: 31.666%;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .one-third-child + .one-third-child:not(:nth-child(3n+4)) {
    margin-left: 2.5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-fourth {
    float: left;
    width: 23.75%;
  }
}
.one-fourth + .one-fourth:not(:nth-of-type(4n+5)) {
  margin-left: 1.666%;
}
.no-scroll {
  overflow: hidden;
}
.cleared:after {
  content: '';
  display: block;
  clear: both;
}
.centered-text {
  text-align: center;
}
.transitioned-basic {
  transition: all 0.2s ease-in-out;
}
.shadowed {
  box-shadow: rgba(0,0,0,0.2) 0.5rem 0.5rem 0;
}
.shaded {
  text-shadow: rgba(0,0,0,0.2) 0.5rem 0.5rem 0;
}
@media screen and (min-width: 1025px) {
  .has-sidebar {
    float: left;
    width: 65.83%;
  }
}
@media screen and (min-width: 1025px) {
  .has-sidebar.left {
    margin-left: 5%;
  }
}
@media screen and (min-width: 1025px) {
  .has-sidebar.right {
    margin-right: 5%;
  }
}
.breadcrumb {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .breadcrumb {
    padding: 9rem 0 6rem 0;
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 1025px) {
  .breadcrumb {
    margin-bottom: 9rem;
    padding-top: 21rem;
    padding-bottom: 10rem;
  }
}
.breadcrumb .section-title {
  text-transform: uppercase;
}
@media screen and (max-width: 1025px) {
  .breadcrumb .generic-text {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 6rem;
  }
}
.has-black-border-bottom {
  position: relative;
}
.has-black-border-bottom:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background-color: rgba(0,0,0,0.95);
  box-shadow: 0.4rem 0.4rem 0 rgba(0,0,0,0.2);
  outline: 0.8rem solid rgba(0,0,0,0.2);
  z-index: 2;
}
#map {
  width: 100%;
  min-height: 42.5rem;
}
#googleMapWrapper {
  position: relative;
}
#googleMapWrapper:before {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: 1.5rem;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 2;
}
.has-shaded-copy {
  position: relative;
}
.has-shaded-copy:before {
  content: attr(data-shade);
  color: #2a0d0e;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  opacity: 0.1;
  font-family: 'Zona Pro Bold';
  letter-spacing: -0.35rem;
  position: absolute;
}
@media screen and (max-width: 1025px) {
  .has-shaded-copy:before {
    font-size: 3.2rem;
    line-height: 3.2rem;
    left: 0;
    width: 110%;
  }
}
@media screen and (min-width: 1025px) {
  .has-shaded-copy:before {
    font-size: 7rem;
    line-height: 7rem;
    left: -18.8rem;
  }
}
@media screen and (max-width: 1025px) {
  .fullscreen-carousel {
    border-top: 2.5rem solid #000;
    border-bottom: 2.5rem solid #000;
    margin: 4rem auto;
  }
}
@media screen and (min-width: 1025px) {
  .fullscreen-carousel {
    border-top: 2.7rem solid #000;
    border-bottom: 2.7rem solid #000;
    margin-top: 6.5rem;
    font-size: 0;
  }
}
.fullscreen-carousel .slick-list {
  margin: 0 -0.5rem;
}
.fullscreen-carousel .slick-slide {
  margin: 0 0.5rem;
}
.fullscreen-carousel-instance {
  position: relative;
  min-height: 32rem;
}
@media screen and (max-width: 1025px) {
  .fullscreen-carousel-instance {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .fullscreen-carousel-instance {
    width: 25%;
    overflow: hidden;
  }
}
.fullscreen-carousel-instance:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  z-index: 3;
  transition: opacity 0.15s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .fullscreen-carousel-instance:before {
    opacity: 1;
  }
}
.fullscreen-carousel-instance:hover:before {
  opacity: 1;
}
.fullscreen-carousel-instance:hover .overlay-triangle {
  transform: translateY(0);
}
.fullscreen-carousel-instance:hover .grows {
  transform: scale(1.2);
}
.fullscreen-carousel-instance:hover .overlay-triangle-information {
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0.2s;
}
.overlay-triangle {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  z-index: 2;
}
@media screen and (min-width: 1025px) {
  .overlay-triangle {
    border-width: 20rem 17rem 0 17rem;
  }
}
@media screen and (min-width: 1700px) {
  .overlay-triangle {
    border-width: 20rem 24rem 0 24rem;
  }
}
.overlay-triangle-information {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  text-align: center;
  width: 21rem;
  opacity: 0;
  transition: opacity 0.15s ease-in-out 0.05s;
}
@media screen and (max-width: 1025px) {
  .overlay-triangle-information {
    opacity: 1;
  }
}
.overlay-triangle-information .fa {
  font-size: 3.2rem;
  color: #fff;
}
.product-name-triangle {
  margin-bottom: 2.8rem;
  font-family: 'Zona Pro Bold';
  font-weight: bold;
  color: #c4161c;
  line-height: 2.6rem;
  font-size: 2.2rem;
}
.grows {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.25s ease-in-out 0.1s;
}
.mobile-carousel-orientation {
  color: #000;
  margin: 3rem auto;
  text-align: center;
}
@media screen and (min-width: 1025px) {
  .mobile-carousel-orientation {
    display: none;
  }
}
.mobile-carousel-orientation i {
  font-size: 1.6rem;
}
@media screen and (max-width: 1025px) {
  .zopim {
    display: none !important;
  }
}
.flexed {
  display: flex;
}
.row {
  flex-flow: row wrap;
}
.spaced {
  justify-content: space-between;
}
.centered {
  align-items: center;
}
.gallery-iframe {
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .gallery-iframe {
    min-height: 400px;
  }
}
@media screen and (min-width: 1025px) {
  .gallery-iframe {
    min-height: 600px;
  }
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.fullscreen-blog-carousel .slick-list {
  margin: 0 -2rem;
}
.fullscreen-blog-carousel .slick-slide {
  margin: 0 2rem;
}
/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: calc(115% - 21px);
  margin: 0;
  z-index: 6;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger-box {
  width: 26px;
  height: 21px;
  display: inline-block;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 26px;
  height: 3px;
  background-color: #c4161c;
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media screen and (min-width: 1025px) {
  .hamburger {
    display: none;
  }
}
.flex {
  display: flex;
}
.swal2-container {
  z-index: 9999 !important;
}
#cta-trigger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 7%;
  right: 2.5%;
}
#cta-trigger.show {
  visibility: visible;
  animation-name: show-cta;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
#trigger-body {
  width: 142px;
  height: 140px;
  padding: 16px;
  background: #fff;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
  border-radius: 7px;
  transition: 0.3s all;
  box-shadow: 2px 3px 0 0 rgba(0,0,0,0.2), 4px 7px 0 0 rgba(0,0,0,0.15);
}
#trigger-body strong {
  font-size: 1.25em;
  font-weight: 600;
  color: #2a2a2a;
  line-height: 1.35;
  margin-bottom: 5px;
  display: block;
}
#trigger-body p {
  font-size: 0.75em;
  font-weight: 400;
  color: #808285;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}
button[open-modal-cta] {
  width: 78px;
  height: 30px;
  background: #c4161c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  line-height: 30px;
  outline: none;
}
button[open-trigger-body] {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4161c;
  border-radius: 50%;
  border: none;
  position: relative;
  margin-top: 40px;
  cursor: pointer;
  outline: none;
}
button[open-trigger-body]:before {
  content: "";
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  position: absolute;
  top: -8px;
  left: -8px;
  background: rgba(42,214,106,0.2);
  border-radius: 50%;
  z-index: -1;
}
button[close-trigger-body] {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -10px;
  border: none;
  cursor: pointer;
  box-shadow: 2px 3px 0 0 rgba(0,0,0,0.2), 4px 7px 0 0 rgba(0,0,0,0.15);
}
#modal-cta {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  z-index: -1;
}
#modal-cta.open {
  z-index: 9999;
  opacity: 1;
  visibility: visible;
}
#modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(155,185,174,0.9);
}
#modal-container {
  width: 520px;
  max-width: 90%;
  height: 460px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fff;
  box-shadow: 16px 20px 20px 0 rgba(0,0,0,0.3);
}
button[modal-tab] {
  width: calc(100% / 3);
  height: 48px;
  border: 1px solid #fff;
  background: #8f8b84;
  font-size: 0.75em;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s all;
}
button[modal-tab].active {
  background: #c4161c;
}
.modal-content {
  width: 100%;
  height: calc(100% - 48px);
  font-size: 16px;
  padding: 11px 14px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
}
.modal-content.active {
  opacity: 1;
  visibility: visible;
}
.modal-content > h2 {
  font-size: 1.875em;
  font-weight: 600;
  color: #c4161c;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
.modal-content > p {
  font-size: 1em;
  font-weight: 400;
  color: #58595b;
  line-height: 1.4;
  margin-bottom: 15px;
  display: block;
  text-align: center;
}
.modal-content label {
  display: block;
  margin-bottom: 10px;
}
.modal-content label.icon-user input,
.modal-content label.icon-phone input {
  padding-left: 37px;
}
.modal-content label.half {
  width: 48%;
  display: inline-block;
  margin-left: 0 !important;
}
.modal-content label.half:nth-child(2n) {
  float: right;
}
.modal-content input,
.modal-content textarea {
  width: 100%;
  border: 2px solid rgba(155,185,174,0.9);
  font-size: 0.875em;
  font-weight: 400;
  color: rgba(155,185,174,0.9);
}
.modal-content input {
  height: 40px;
  border-radius: 30px;
  padding-left: 15px;
}
.modal-content textarea {
  height: 83px;
  border-radius: 10px;
  padding: 15px;
}
.modal-content .btn-submit {
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 30px;
  background: #c4161c;
  display: block;
  font-size: 1.25em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 42px;
  text-align: center;
  margin-bottom: 13px;
  color: #fff;
}
.modal-content small {
  font-size: 0.875em;
  font-weight: 400;
  color: #c4161c;
  text-align: center;
  display: block;
}
.modal-content small span {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #f00;
  color: #fff;
  display: inline-block;
  text-align: center;
}
@media screen and (max-width: 991px) {
  #cta-trigger {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  #whats-cta {
    width: 47px;
    height: 47px;
    background: #01c854;
    border-radius: 50%;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    font-size: 1.875rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 5%;
    bottom: 50%;
  }
  #whats-cta:before {
    content: "";
    width: calc(100% + 14px);
    height: calc(100% + 14px);
    position: absolute;
    top: -7px;
    left: -7px;
    z-index: -1;
    background: rgba(1,200,84,0.3);
    border-radius: 50%;
    animation-name: whats-pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  #whats-cta.show {
    visibility: visible;
    animation-name: show-cta;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
}
@media screen and (min-width: 991px) {
  #mobile-cta {
    display: none;
  }
}
@media screen and (max-width: 990px) {
  #mobile-cta {
    width: 100%;
    height: 42px;
    background: #c4161c;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  #mobile-cta:before {
    content: "";
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 100%;
    left: 0;
  }
  #mobile-cta .wrap {
    max-width: 100%;
  }
  #btn-whatsapp {
    width: 50px;
    background: #01c854;
    position: relative;
    font-size: 1.75rem;
    color: #fff;
    line-height: 42px;
    padding-left: 16px;
  }
  #btn-whatsapp:before {
    content: "";
    width: 300px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 100%;
    background: #01c854;
  }
  #btn-ligar,
  #btn-open-modal {
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 42px;
    text-transform: uppercase;
  }
  #btn-ligar {
    width: 35%;
    position: relative;
    font-size: 9px;
  }
  #btn-ligar:after {
    content: "";
    width: 1px;
    height: 35px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  #btn-open-modal {
    width: 35%;
    border: none;
    background: transparent;
    margin: 0 auto;
    font-size: 9px;
  }
}
@-moz-keyframes whats-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes whats-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes whats-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes whats-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes show-cta {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-30px);
    opacity: 1;
  }
}
@-webkit-keyframes show-cta {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-30px);
    opacity: 1;
  }
}
@-o-keyframes show-cta {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-30px);
    opacity: 1;
  }
}
@keyframes show-cta {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-30px);
    opacity: 1;
  }
}
.red-stripe-top {
  background-color: #161616;
}
@media screen and (max-width: 1025px) {
  .red-stripe-top {
    text-align: center;
  }
}
.red-stripe-top .logo {
  display: block;
  margin-bottom: 5rem;
}
@media screen and (max-width: 1025px) {
  .red-stripe-top .logo {
    margin-left: auto;
    margin-right: auto;
  }
}
.red-stripe-top .fa {
  font-size: 2.4rem;
  color: #c4161c;
  margin-right: 0.2rem;
}
.red-stripe-top a {
  transition: color 0.2s ease-in-out;
}
.red-stripe-top a:hover {
  color: #c4161c;
}
@media screen and (min-width: 1025px) {
  .footer-column {
    margin-bottom: 7.7rem;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:first-of-type {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:first-of-type .generic-text {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:last-of-type {
    padding-bottom: 6rem;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column:last-of-type {
    padding-top: 5rem;
  }
}
.stripe {
  width: 100%;
  display: block;
  height: 7rem;
  position: relative;
}
.stripe.red {
  background-color: #c4161c;
}
.stripe.red .generic-text {
  line-height: 7rem;
}
.stripe.red .smaller-lh {
  line-height: 6rem;
}
.stripe.has-low-margin {
  border-bottom: 1rem solid #0d0d0d;
}
@media screen and (min-width: 1025px) {
  .stripe.has-low-margin {
    margin-bottom: 5rem;
  }
}
.stripe.black {
  padding-top: 1.5rem;
  background-color: #000;
  border-top: 1rem solid rgba(0,0,0,0.2);
}
@media screen and (max-width: 1025px) {
  .stripe .generic-text {
    font-size: 1.3rem;
  }
}
.footer-title {
  font-size: 2.4rem;
  color: #c4161c;
  font-family: 'Zona Pro Bold';
  margin-bottom: 1.5rem;
  display: block;
  transition: color 0.2s ease-in-out;
}
.footer-title.bigger-margin {
  margin-bottom: 2.5rem;
}
.footer-title:hover {
  color: #fff !important;
}
.phone-instance {
  font-family: 'Zona Pro Bold';
  font-size: 2rem;
  line-height: 2.6rem;
  color: #fff;
  display: block;
}
.phone-instance a {
  color: inherit;
}
@media screen and (max-width: 1025px) {
  .whatsapp-footer {
    margin: 3rem 0;
  }
}
@media screen and (min-width: 1025px) {
  .whatsapp-footer {
    margin: 2rem 0;
  }
}
.whatsapp-footer a {
  color: inherit;
}
@media screen and (max-width: 1025px) {
  .email-footer {
    margin-bottom: 3rem;
  }
}
.email-footer a {
  color: inherit;
}
.footer-subtitle {
  font-size: 1.6rem;
  color: #fff;
  font-family: 'Zona Pro Bold';
  margin-bottom: 1.5rem;
  display: block;
}
.footer-list {
  margin-bottom: 3rem;
}
.footer-list li {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-family: 'Helvetica';
}
.footer-list li a {
  color: #fff;
  transition: color 0.2s ease-in-out;
}
@media screen and (min-width: 1025px) {
  .signature {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 1025px) {
  .signature-link {
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .signature-link {
    color: rgba(255,255,255,0.2);
    transform: translateY(-11px);
    display: inline-block;
    margin-right: 10px;
  }
}
.signature-link:hover {
  color: #fff;
}
.fixed-bottom-menu {
  position: fixed;
  bottom: 0;
  background-color: #c4161c;
  width: 100%;
  z-index: 5;
  font-size: 0;
  height: 4.5rem;
}
@media screen and (min-width: 1025px) {
  .fixed-bottom-menu {
    display: none;
  }
}
.fixed-bottom-menu .cta-bottom {
  width: 50%;
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
  font-family: 'Zona Pro Bold';
  line-height: 4.5rem;
  position: relative;
}
.fixed-bottom-menu .cta-bottom:last-of-type:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  height: 3.2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.1rem;
  background-color: #fff;
}
.fixed-bottom-menu .cta-bottom i {
  font-size: 18px;
  margin-right: 5px;
}
.whatsapp-cta {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 4;
}
@media screen and (max-width: 1025px) {
  .whatsapp-cta {
    display: none;
  }
}
.whatsapp-cta:hover {
  transform: translateY(-50%) scale(1.2);
}
.field {
  width: 100%;
  border: 0.2rem solid #000;
  padding: 0.5rem;
}
.field:active,
.field:focus {
  background-color: #e6e6e6;
  transition: background-color 0.2s ease-in-out;
}
.file {
  position: relative;
}
.file .hidden-file {
  position: absolute;
  opacity: 0;
}
.budget-request {
  background-color: rgba(0,0,0,0.6);
  outline: 1.1rem solid rgba(255,255,255,0.2);
  padding: 4rem 2rem;
  max-width: 38rem;
}
.budget-request .field {
  height: 3rem;
  background-color: #fff;
  padding: 0.5rem 1rem;
  width: 100%;
  color: #414042;
  font-size: 1.2rem;
  border: 0;
}
.budget-request .field.bigger {
  height: 10rem;
}
.budget-request .field-wrapper {
  margin-bottom: 1.5rem;
}
.budget-request .form-title {
  font-family: 'Zona Pro Bold';
  font-size: 2.6rem;
  color: #fff;
  margin-bottom: 1.5rem;
}
.budget-request .btn-default.full-red {
  padding-top: 0;
  height: 3rem;
  width: 100%;
  font-size: 1.8rem;
  border: 0.1rem solid #e30613;
}
.budget-request .btn-default.full-red:hover {
  background-color: #fff;
  color: #e30613;
}
@media screen and (min-width: 1025px) {
  .budget-request .half + .half {
    margin-left: 5%;
  }
}
.main-contact-form .field-wrapper {
  display: block;
}
@media screen and (max-width: 1025px) {
  .main-contact-form .field-wrapper {
    margin-bottom: 3rem;
  }
}
@media screen and (min-width: 1025px) {
  .main-contact-form .field-wrapper {
    margin-bottom: 2rem;
  }
}
.main-contact-form .field-description {
  display: block;
  color: #6d6e71;
  margin-bottom: 1rem;
}
.main-contact-form .field {
  height: 4.5rem;
}
.main-contact-form .field.bigger {
  height: 20rem;
}
.we-call-form {
  display: none;
}
@media screen and (max-width: 1025px) {
  .we-call-form {
    padding: 0 1.5rem;
  }
}
@media screen and (min-width: 1025px) {
  .we-call-form {
    position: absolute;
    left: 15%;
    top: 35%;
    transform: rotate(-45deg);
  }
}
.we-call-form:before {
  left: -9.5rem;
  top: -1.5rem;
}
.we-call-form:after {
  top: 4.5rem;
  left: -11.3rem;
}
.we-call-form legend {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}
@media screen and (max-width: 1025px) {
  .we-call-form legend {
    color: #fff;
    text-align: left;
  }
}
.we-call-form .field-wrapper {
  margin-bottom: 1.2rem;
}
@media screen and (max-width: 1025px) {
  .we-call-form .field-wrapper {
    display: block;
  }
}
.we-call-form .field {
  height: 4rem;
}
@media screen and (min-width: 1025px) {
  .we-call-form .field {
    background-color: #fff;
    width: 24.6rem;
  }
}
.we-call-form .field:focus,
.we-call-form .field:active {
  background-color: #d9d9d9;
}
@media screen and (min-width: 1025px) {
  .we-call-form .field.bigger {
    width: 36.7rem;
  }
}
.work-with-us-form {
  transform: rotate(-45deg);
  max-width: 52rem;
  position: absolute;
  top: 13.5rem;
  right: -6rem;
}
.work-with-us-form legend {
  font-size: 1.8rem;
  margin-bottom: 3rem;
  max-width: 25rem;
}
.work-with-us-form .field-wrapper {
  margin-bottom: 2rem;
}
.work-with-us-form .field {
  height: 3.6rem;
}
.work-with-us-form:before {
  left: -16rem;
  top: 10rem;
}
.work-with-us-form:after {
  top: 15.6rem;
  left: 17.7rem;
}
.recaptcha {
  height: 5.8rem;
  background-color: #fff;
}
@media screen and (min-width: 1025px) {
  .whatsapp-header-cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.whatsapp-header-cta i {
  color: #fff !important;
  margin-right: 5px;
}
.skews {
  position: fixed;
  width: 100%;
  display: block;
  z-index: 1000000000000000000;
  top: 0;
  padding-top: 5rem;
}
.skews .generic-text {
  font-size: 2rem;
  font-weight: bold;
  line-height: 5rem;
}
@media screen and (min-width: 1025px) {
  .skews .generic-text {
    margin-left: 2rem;
  }
}
.skews .fa {
  font-size: 2.4rem;
  color: #c4161c;
  line-height: 5rem;
}
@media screen and (max-width: 1025px) {
  .skews .generic-text {
    display: none;
  }
}
.bgstripe-opaque {
  height: 5rem;
  background-color: rgba(0,0,0,0.4);
  font-size: 0;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 0;
  max-width: 120rem;
  border-bottom-left-radius: 5.5rem;
  border-bottom-right-radius: 5.5rem;
}
@media screen and (max-width: 1025px) {
  .bgstripe-opaque {
    display: none;
  }
}
.skewed-whatsapp {
  margin-left: 2rem;
}
[class*="skewed"] {
  height: 5rem;
  width: 5rem;
  background-color: #000;
  outline: 0.8rem solid rgba(255,255,255,0.2);
  transform: skewX(35deg);
  text-align: center;
}
@media screen and (max-width: 1025px) {
  [class*="skewed"] {
    display: none;
  }
}
.unskew {
  transform: skewX(-35deg);
}
.triangle-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  text-align: center;
  top: 0;
}
@media screen and (max-width: 1025px) {
  .triangle-wrapper {
    width: 40%;
  }
}
@media screen and (min-width: 1025px) {
  .triangle-wrapper {
    width: 50rem;
    height: 27rem;
  }
}
.triangle {
  width: 100%;
  background: url("../images/common/logo-wrapper.png") no-repeat top center/contain;
  display: block;
  position: absolute;
  top: -5rem;
  left: 0;
  right: 0;
  margin: auto;
  transition: background-color 0.2s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .triangle {
    height: 15.7rem;
    width: 100vw;
    left: -30vw;
    pointer-events: none;
  }
}
@media screen and (min-width: 1025px) {
  .triangle {
    height: 100%;
  }
}
.triangle.is-active {
  background-color: rgba(0,0,0,0.97);
}
.logo {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1025px) {
  .logo {
    max-width: 120px;
    top: -3.5rem;
  }
}
@media screen and (min-width: 1025px) {
  .logo {
    max-width: 15.5rem;
    max-height: 8.5rem;
    margin-top: -3rem;
  }
}
.logo-small {
  float: left;
  display: block;
  padding-top: 1rem;
}
@media screen and (max-width: 1025px) {
  .logo-small {
    display: none;
  }
}
.still {
  transition: transform 0.35s ease-in-out;
}
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0.4rem 0 rgba(0,0,0,0.2);
  transition: transform 0.35s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .fixed {
    height: 9rem;
    background-color: rgba(0,0,0,0.9);
  }
}
@media screen and (min-width: 1025px) {
  .fixed {
    background-color: rgba(0,0,0,0.97);
    min-height: 5rem;
    transform: translateY(-100%);
  }
}
@media screen and (max-width: 1025px) {
  .header-links {
    position: absolute;
    left: 0;
    height: 100vh;
    background-color: #2a2a2a;
    top: 0;
    padding-top: 16rem;
    z-index: 2;
    width: 100%;
    transition: all 0.25s ease-in-out;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 1025px) {
  .header-links {
    font-size: 0;
    display: block;
    float: left;
  }
}
.header-links:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -2;
  height: 100%;
}
.header-links:before {
  content: '';
  background-color: $blue;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 1025px) {
  .header-links:not(.is-active) {
    transform: translateX(-150%);
  }
}
.link {
  color: #fff;
  font-size: 1.5rem;
}
@media screen and (max-width: 1025px) {
  .link {
    font-family: 'Zona Pro Bold';
    line-height: 3.8rem;
    display: block;
    padding-left: 1.5rem;
    background-color: #2a2a2a;
  }
}
@media screen and (max-width: 1025px) {
  .link:not(:last-of-type) {
    border-bottom: 0.1rem solid #000;
  }
}
.link:after {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  height: 0.2rem;
  background-color: #c4161c;
  transition: width 0.25s ease-in-out;
  margin: auto;
}
.link:hover > a {
  color: #c4161c;
}
.link a {
  color: inherit;
}
.link:not(.nolowborder) {
  position: relative;
}
@media screen and (min-width: 1025px) {
  .link:not(.nolowborder):hover:after {
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .link + .link {
    margin-left: 3.5rem;
  }
}
@media screen and (max-width: 1025px) {
  .link.has-schedule-button {
    position: absolute;
    bottom: 15vh;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 0;
    text-align: center;
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .link.has-sublinks:after {
    content: '';
    display: block;
    clear: both;
  }
}
@media screen and (max-width: 1025px) {
  .link.has-sublinks .svg-inline--fa {
    display: inline-block;
    vertical-align: top;
    float: right;
    font-size: 1.6rem;
    color: $teal;
    margin-top: 1.2rem;
  }
}
@media screen and (min-width: 1025px) {
  .link.has-sublinks .svg-inline--fa {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .link.has-sublinks:hover .mega-menu {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
@media screen and (min-width: 1025px) {
  .link:not(.has-schedule-button) {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }
}
@media screen and (max-width: 1025px) {
  .link.phone-and-whatsapp {
    display: block;
    position: absolute;
    margin-top: 5rem;
    bottom: 0rem;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    border-top: 0.5rem solid rgba(0,0,0,0.7);
    border-bottom: 0.5rem solid rgba(0,0,0,0.7);
    padding: 1.5rem;
    font-family: 'Zona Pro Bold';
  }
}
@media screen and (min-width: 1025px) {
  .link.phone-and-whatsapp {
    display: none;
  }
}
.link.phone-and-whatsapp a {
  display: block;
  font-size: 2rem;
  color: #fff;
  line-height: 1.8rem;
}
.link.phone-and-whatsapp .fa {
  line-height: initial;
  color: #c4161c;
  font-size: 2rem;
}
@media screen and (max-width: 1025px) {
  .sublinks {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 1025px) {
  .sublinks.is-active {
    margin-bottom: 1.5rem;
  }
}
.sublinks li {
  color: #fff;
  line-height: initial;
}
@media screen and (max-width: 1025px) {
  .sublinks li {
    padding-top: 1.5rem;
  }
}
@media screen and (max-width: 1025px) {
  .sublinks li:not(:last-of-type) {
    padding-bottom: 1.5rem;
  }
}
.sublinks li:not(.is-category) {
  font-size: 1.4rem;
  font-family: 'Zona Pro Regular';
}
.sublinks li a:hover,
.sublinks li a:active {
  color: #c4161c;
}
.is-category {
  font-family: 'Zona Pro Bold' !important;
  font-size: 1.6rem;
}
@media screen and (min-width: 1025px) {
  .is-category {
    margin-bottom: 2rem;
  }
}
.duplicate-title {
  font-size: 2.4rem;
  font-family: 'Zona Pro Bold';
  color: #c4161c;
}
@media screen and (max-width: 1025px) {
  .duplicate-title {
    padding: 0.5rem 1.5rem;
    background-color: rgba(0,0,0,0.4);
    border-bottom: 0.2rem solid #000;
    border-top: 0.2rem solid #000;
  }
}
.mega-menu {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  padding: 5rem 0;
  background-color: rgba(40,40,40,0.98);
  overflow: hidden;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-bottom: 0.7rem solid #000;
}
@media screen and (max-width: 1025px) {
  .mega-menu {
    width: 100%;
    z-index: 5;
    top: 21.6rem;
    height: 100vh;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 1025px) {
  .mega-menu {
    width: 100vw;
    top: 10.9rem;
    transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out, padding 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-30px);
    padding: 5rem;
  }
}
@media screen and (max-width: 1025px) {
  .mega-menu:not(.is-active) {
    transform: translateX(-100%);
  }
}
@media screen and (min-width: 1025px) {
  .menu-column {
    float: left;
    width: 15.83%;
  }
}
.menu-column + .menu-column {
  margin-left: 1%;
}
@media screen and (min-width: 1025px) {
  .menu-column:nth-of-type(2) {
    position: relative;
  }
}
@media screen and (min-width: 1025px) {
  .menu-column:nth-of-type(2):after {
    height: 100%;
    content: '';
    position: absolute;
    left: -3.5rem;
    top: 0;
    width: 0.1rem;
    background-color: #c4161c;
  }
}
.menu-column:not(:first-of-type) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 1025px) {
  .menu-column:not(:last-of-type) li:not(:last-of-type) {
    border-bottom: 0.2rem solid #000;
  }
}
.image-column {
  box-shadow: 7px 7px 0 #000, 10px 10px 0 rgba(0,0,0,0.2);
}
@media screen and (max-width: 1025px) {
  .image-column {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .image-column {
    float: right;
    max-width: 580px;
    width: 45%;
  }
}
.image-column img {
  box-shadow: inset 0 6px 0 rgba(0,0,0,0.6);
}
.up {
  transform: translateY(-20rem);
}
.down {
  transform: translateY(0) !important;
}
.upper-partition {
  border-bottom: 2px solid rgba(255,255,255,0.25);
}
@media screen and (max-width: 1025px) {
  .upper-partition {
    display: none;
  }
}
.upper-partition .center-content {
  min-height: 40px;
}
.upper-partition .generic-text {
  margin-left: 0 !important;
}
.upper-partition a {
  transition: all 0.35s ease;
}
.upper-partition a + a {
  margin-left: 20px;
}
.upper-partition a:hover {
  color: #c4161c;
}
.upper-partition a i {
  margin-right: 10px;
  position: relative;
  bottom: -2px;
}
@media screen and (max-width: 1025px) {
  .has-budget-form {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .has-budget-form {
    float: left;
    width: 29.17%;
    margin-bottom: 10rem;
    position: sticky;
    top: 130px;
  }
}
.has-budget-form .shadowed {
  border: 0.2rem solid #d1d3d4;
  padding: 4rem 2rem;
}
.has-budget-form .shadowed:not(:last-of-type) {
  margin-bottom: 4rem;
}
.has-budget-form .budget-request {
  background-color: #000;
  border: 0.2rem solid #c4161c;
}
.sidebar-title {
  font-size: 2.4rem;
  color: #c4161c;
  margin-bottom: 2rem;
  font-family: 'Zona Pro Bold';
}
.sidebar-title i {
  font-size: 2.8rem;
  color: #000;
  margin-right: 0.2rem;
}
.sidebar-preview {
  display: block;
}
.sidebar-preview:not(:last-of-type) {
  border-bottom: 0.1rem solid #e5e5e5;
  padding-bottom: 2rem;
}
.sidebar-image-wrapper {
  border: 0.6rem solid #000;
  padding: 0.2rem;
  text-align: center;
  margin-bottom: 1rem;
}
.sidebar-post-name {
  font-family: 'Zona Pro Bold';
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: #000;
}
.category-listing li {
  padding: 0.6rem 0;
}
.category-listing li:not(:last-of-type) {
  border-bottom: 0.1rem solid #d1d3d4;
}
.category-listing li a {
  color: #000;
  transition: color 0.2s ease-in-out;
}
.category-listing li a:hover {
  color: #e30613;
}
.category-listing li i {
  font-size: 1.8rem;
  margin-right: 0.3rem;
}
.prop {
  position: absolute;
  transform: rotate(45deg);
}
@media screen and (max-width: 1025px) {
  .prop {
    display: none;
  }
}
.prop.shadowed {
  box-shadow: 1.5rem 1.5rem 0rem rgba(0,0,0,0.2);
}
@media screen and (max-width: 1025px) {
  .prop.mobile {
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .prop.mobile {
    display: none;
  }
}
.solid.red {
  background-color: #c4161c;
}
.solid.black {
  background-color: #000;
}
.transparent {
  border: 4rem solid #000;
  overflow: hidden;
  background-color: transparent;
}
.transparent .background {
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.opening-props .prop.solid.black {
  height: 40rem;
  width: 40rem;
  bottom: -35rem;
  z-index: 4;
}
@media screen and (min-width: 1025px) {
  .opening-props .prop.solid.black {
    right: -40rem;
  }
}
@media screen and (min-width: 1700px) {
  .opening-props .prop.solid.black {
    right: -18rem;
  }
}
.opening-props .prop.mobile {
  width: 23.6rem;
  height: 23.6rem;
  left: -62%;
  bottom: -19%;
}
.about-props.center-content .small {
  top: -17rem;
}
@media screen and (min-width: 1025px) {
  .about-props.center-content .small {
    left: -60rem;
  }
}
@media screen and (min-width: 1700px) {
  .about-props.center-content .small {
    left: -62rem;
  }
}
.about-props.center-content .prop.red {
  top: -44.5rem;
  left: -39rem;
}
.about-props .prop.red {
  width: 24rem;
  height: 24rem;
  top: -12.5rem;
  z-index: 3;
}
@media screen and (min-width: 1025px) {
  .about-props .prop.red {
    left: -12.5rem;
  }
}
@media screen and (min-width: 1700px) {
  .about-props .prop.red {
    left: 15.5rem;
  }
}
.about-props .huge {
  width: 168.4rem;
  height: 168.4rem;
  top: 11rem;
  z-index: 3;
}
@media screen and (min-width: 1025px) {
  .about-props .huge {
    right: -160rem;
  }
}
@media screen and (min-width: 1700px) {
  .about-props .huge {
    right: -130rem;
  }
}
.about-props .huge .background {
  width: 106rem;
  height: 160rem;
  left: auto;
  background-position: center center;
  right: 62rem;
  top: 52rem;
  background-size: contain;
}
.about-props .small {
  top: -4rem;
  width: 44rem;
  height: 44rem;
  z-index: 2;
}
@media screen and (min-width: 1025px) {
  .about-props .small {
    left: -60rem;
  }
}
@media screen and (min-width: 1700px) {
  .about-props .small {
    left: -27rem;
  }
}
.about-props .small .background {
  width: 100%;
  height: 125%;
  background-size: 65%;
  top: -14.2rem;
  left: 12.5rem;
  right: 0;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
@media screen and (max-width: 1025px) {
  .overlay {
    background-color: rgba(0,0,0,0.9);
  }
}
@media screen and (min-width: 1025px) {
  .overlay {
    background-color: rgba(0,0,0,0.99);
  }
}
.overlay.is-open {
  display: block;
}
.modal-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 1025px) {
  .modal-wrapper {
    top: 7rem;
  }
}
@media screen and (min-width: 1025px) {
  .modal-wrapper {
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  .modal-background-abstracted {
    border: 1.4rem solid #353535;
    background-color: #c4161c;
    display: inline-block;
    vertical-align: top;
    transform: rotate(45deg);
  }
}
@media screen and (max-width: 1025px) {
  .modal-background {
    height: 85vh;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 1025px) {
  .modal-background.call {
    width: 36rem;
    height: 36rem;
  }
}
.modal-background.call .work-with-us-form,
.modal-background.call .budget-request {
  display: none;
}
@media screen and (min-width: 1025px) {
  .modal-background.work {
    width: 60rem;
    height: 60rem;
  }
}
.modal-background.work .we-call-form,
.modal-background.work .budget-request {
  display: none;
}
.modal-background.budget .we-call-form,
.modal-background.budget .work-with-us-form {
  display: none !important;
}
.modal-background .field-description {
  color: #fff;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1025px) {
  .modal-background .we-call-form:before,
  .modal-background .work-with-us-form:before,
  .modal-background .we-call-form:after,
  .modal-background .work-with-us-form:after {
    content: '';
    position: absolute;
    display: block;
    background-color: #fff;
    transform: rotate(45deg);
  }
}
@media screen and (min-width: 1025px) {
  .modal-background .we-call-form:before,
  .modal-background .work-with-us-form:before {
    width: 2.3rem;
    height: 2.3rem;
  }
}
@media screen and (min-width: 1025px) {
  .modal-background .we-call-form:after,
  .modal-background .work-with-us-form:after {
    width: 5.7rem;
    height: 5.7rem;
  }
}
.modal-background .budget-request {
  outline: 0;
  background-color: transparent;
}
.modal-background .budget-request .field-wrapper {
  display: block;
}
@media screen and (max-width: 1025px) {
  .modal-persona {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .modal-persona {
    position: absolute;
    top: -27rem;
    right: -5.5rem;
    transform: rotate(-45deg);
    display: none;
  }
}
.loader-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.loader-wrapper .prop {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
}
.loader-wrapper .prop.red {
  height: 36rem;
  width: 36rem;
  top: -20rem;
  animation: rotate 3s ease-in-out infinite reverse;
}
.loader-wrapper .prop.black {
  height: 24rem;
  width: 24rem;
  top: -14rem;
  animation: rotate 3s ease-in-out infinite forwards;
}
.loader-logo {
  text-align: center;
  font-size: 2.4rem;
  color: #fff;
  font-family: 'Zona Pro Bold';
  letter-spacing: 0.5rem;
  top: -3.4rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  animation: pulse 3s ease-in-out infinite forwards;
}
body:not(.loaded) .modal-wrapper {
  display: none;
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-225deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-225deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-225deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-225deg);
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media screen and (max-width: 1025px) {
  html {
    margin-top: 5.6rem;
  }
}
.oldiewarning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #32363b;
  overflow: hidden;
  z-index: 1000;
  display: none;
}
.oldiewarning p,
.oldiewarning a {
  width: 100%;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 500px;
  color: #fff;
  font-size: 40px;
  font-family: Tahoma, Geneva, sans-serif;
}
.lt-ie9 {
  overflow: hidden;
}
.lt-ie9 .oldiewarning {
  display: block;
}
.sr-only {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -99999rem;
}
.wp-wrap p {
  color: #6d6e71;
  margin-bottom: 3rem;
}
.wp-wrap h2 {
  font-family: 'Zona Pro Bold';
  margin-bottom: 2rem;
  font-size: 2rem;
  text-transform: uppercase;
}
.wp-wrap h3 {
  font-family: 'Zona Pro Bold';
  font-size: 3rem;
  color: #e30613;
  margin-bottom: 3rem;
  text-transform: uppercase;
}
.wp-wrap h4 {
  font-family: 'Zona Pro Bold';
  font-size: 2rem;
  color: #000;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.wp-wrap ul,
.wp-wrap ol {
  margin: 2rem 0;
  list-style-type: disc;
  padding-left: 1.5rem;
}
.wp-wrap li {
  font-size: 1.6rem;
  font-family: 'Helvetica';
  line-height: 2.4rem;
  color: #6d6e71;
}
.wp-wrap a {
  font-size: 1.6rem;
  color: #e30613;
  text-decoration: underline !important;
}
.sr-only {
  position: absolute;
  opacity: 0;
  top: -999999rem;
}
.opening .opening-carousel-instance {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (max-width: 1025px) {
  .opening .opening-carousel-instance {
    min-height: 50rem;
  }
}
@media screen and (min-width: 1025px) {
  .opening .opening-carousel-instance {
    min-height: 80rem;
  }
}
@media screen and (max-width: 1025px) {
  .opening .section-title {
    margin-bottom: 4rem;
    padding-top: 9.6rem;
  }
}
@media screen and (min-width: 1025px) {
  .opening .section-title {
    padding-top: 20rem;
    margin-bottom: 3rem;
    max-width: 56rem;
  }
}
@media screen and (max-width: 1025px) {
  .opening .generic-text {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 1025px) {
  .opening .generic-text {
    max-width: 45rem;
  }
}
.opening .btn-default {
  height: 5rem;
  width: 22rem;
  display: block;
  padding-top: 0.8rem;
}
@media screen and (min-width: 1025px) {
  .opening .btn-default {
    margin-top: 1rem;
  }
}
.opening .budget-request {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 1025px) {
  .opening .budget-request {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .opening .budget-request {
    top: 17rem;
  }
}
@media screen and (max-width: 1025px) {
  .about {
    padding: 10rem 0 0 0;
  }
}
@media screen and (min-width: 1025px) {
  .about {
    padding-top: 13.5rem;
    padding-bottom: 9.5rem;
    position: relative;
  }
}
@media screen and (min-width: 1025px) {
  .about .generic-text {
    max-width: 70rem;
  }
}
@media screen and (max-width: 1025px) {
  .about .generic-text:last-of-type {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 1025px) {
  .about .has-shaded-copy:before {
    max-width: 114rem;
  }
}
.woman {
  position: absolute;
  top: -6rem;
  z-index: 2;
  display: none;
}
@media screen and (min-width: 1025px) {
  .woman {
    display: block;
    right: -6rem;
  }
}
@media screen and (min-width: 1700px) {
  .woman {
    right: 21.5rem;
  }
}
.persona-mobile {
  width: 100%;
  height: 86rem;
  margin: 3rem 0;
  background: url("../images/backgrounds/02.jpg") no-repeat right center/contain;
}
@media screen and (min-width: 1025px) {
  .persona-mobile {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .customers {
    max-width: 70rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 1025px) {
  .customers .customer-image {
    display: inline-block;
    vertical-align: top;
  }
}
.customers .slick-current .customer-image {
  filter: grayscale(0);
}
@media screen and (max-width: 1025px) {
  .customer-instance {
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  .customer-instance {
    width: 20%;
  }
}
.customer-instance + .customer-instance:not(:nth-of-type(4n+5)) {
  margin-left: 4rem;
}
@media screen and (min-width: 1025px) {
  .customer-instance:nth-of-type(-n+4) {
    margin-bottom: 5.5rem;
  }
}
.customer-image {
  filter: grayscale(100%);
}
.customer-image:hover {
  filter: grayscale(0);
}
@media screen and (min-width: 1025px) {
  .types .generic-text {
    max-width: 90rem;
  }
}
@media screen and (max-width: 1025px) {
  .types .has-shaded-copy:before {
    max-width: 29rem;
  }
}
@media screen and (min-width: 1025px) {
  .types .has-shaded-copy:before {
    max-width: 36rem;
  }
}
.contact {
  position: relative;
  background: url("../images/backgrounds/01.jpg") no-repeat center center/cover;
}
@media screen and (max-width: 1025px) {
  .contact {
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 1025px) {
  .contact {
    padding: 2rem 0 9rem 0;
  }
}
.contact .prop.left {
  width: 66.4rem;
  height: 66.4rem;
  top: -27rem;
}
@media screen and (min-width: 1025px) {
  .contact .prop.left {
    left: -75rem;
  }
}
@media screen and (min-width: 1700px) {
  .contact .prop.left {
    left: -50rem;
  }
}
.contact .prop.left .background {
  width: 50rem;
  height: 50rem;
  top: -7rem;
  left: 16rem;
}
.contact .prop.solid {
  height: 20.6rem;
  width: 20.6rem;
  bottom: 10.5rem;
}
@media screen and (min-width: 1025px) {
  .contact .prop.solid {
    right: -30rem;
  }
}
@media screen and (min-width: 1700px) {
  .contact .prop.solid {
    right: 5.5rem;
  }
}
.contact .prop.transparent.right {
  top: 4%;
  width: 61.4rem;
  height: 61.4rem;
}
@media screen and (min-width: 1025px) {
  .contact .prop.transparent.right {
    right: -50%;
  }
}
@media screen and (min-width: 1700px) {
  .contact .prop.transparent.right {
    right: -19%;
  }
}
.contact .prop.transparent.right .background {
  width: 50rem;
  height: 70rem;
  top: 7rem;
  left: -16rem;
}
.contact:after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: 1.5rem;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
}
@media screen and (min-width: 1025px) {
  .contact .has-shaded-copy:before {
    max-width: 44rem;
  }
}
@media screen and (min-width: 1025px) {
  .contact-information {
    margin-bottom: 3.2rem;
  }
}
@media screen and (min-width: 1025px) {
  .contact-information-instance {
    font-size: 0;
  }
}
@media screen and (max-width: 1025px) {
  .contact-information-instance:not(:last-of-type) {
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 1025px) {
  .contact-information-instance:last-of-type {
    margin-bottom: 8rem;
  }
}
.contact-information-title {
  font-family: 'Zona Pro Bold';
  font-size: 2.8rem;
  margin-bottom: 3rem;
}
.contact-information-title span {
  font-family: 'Zona Pro Regular' !important;
  font-size: 2rem;
  display: block;
  font-family: 'Zona Pro Regular';
}
.icon-wrapper {
  font-size: 4rem;
  color: #c4161c;
  margin-right: 1.5rem;
}
@media screen and (min-width: 1025px) {
  .icon-wrapper {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 1025px) {
  .icon-wrapper .fa-phone {
    margin-top: 1.2rem;
  }
}
.contact-details {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: 'Zona Pro Bold';
  padding-top: 0.6rem;
}
.contact-details a {
  color: #6d6e71;
}
.contact-details a:hover {
  color: #c4161c;
}
@media screen and (max-width: 1025px) {
  .products {
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  .products {
    padding-bottom: 8rem;
  }
}
.product,
.blog-post-instance,
.workplace-spot-instance {
  border: 1rem solid #000;
  padding-top: 2.2rem;
  padding-bottom: 0.8rem;
  position: relative;
}
@media screen and (max-width: 1025px) {
  .product,
  .blog-post-instance,
  .workplace-spot-instance {
    max-width: 30rem;
  }
}
@media screen and (min-width: 1025px) {
  .product,
  .blog-post-instance,
  .workplace-spot-instance {
    margin-bottom: 5rem;
    min-height: 31rem;
  }
}
.product-full-title {
  font-size: 3rem;
  color: #e30613;
  font-family: 'Zona Pro Bold';
  display: block;
  margin-bottom: 3.2rem;
}
@media screen and (min-width: 1025px) {
  .product-full-title {
    margin: 0 auto 2.2rem 0;
  }
}
.product-title,
.blog-post-title {
  font-size: 2rem;
  color: #e30613;
  font-family: 'Zona Pro Bold';
  display: block;
}
@media screen and (max-width: 1025px) {
  .product-title,
  .blog-post-title {
    text-align: center;
    margin-bottom: 2.2rem;
  }
}
@media screen and (min-width: 1025px) {
  .product-title,
  .blog-post-title {
    max-width: 15.5rem;
    margin: 0 auto 2.2rem auto;
  }
}
.image-wrapper {
  margin-bottom: 1.1rem;
}
@media screen and (max-width: 1025px) {
  .image-wrapper {
    text-align: center;
  }
}
.full-product {
  border: 1rem solid #000;
  padding: 0.5rem;
}
.full-product .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid transparent;
  background-color: #c4161c;
  color: transparent;
  font-size: 0;
  z-index: 3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  transition: all 0.2s ease;
}
.full-product .slick-arrow:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #fff;
  font-family: 'fontAwesome';
  transition: all 0.2s ease;
}
.full-product .slick-arrow:hover {
  background-color: #fff;
}
.full-product .slick-arrow:hover:before {
  color: #c4161c;
}
.full-product .slick-prev {
  left: -11px;
  right: auto;
}
.full-product .slick-prev:before {
  content: '\f053';
}
.full-product .slick-next {
  right: -11px;
  left: auto;
}
.full-product .slick-next:before {
  content: '\f054';
}
.post-detail-text {
  padding-top: 7rem;
}
@media screen and (max-width: 1025px) {
  .post-detail-text {
    text-align: center;
    padding-bottom: 6rem;
  }
}
@media screen and (max-width: 1025px) {
  .post-products-description {
    margin-bottom: 4rem;
  }
}
.link-banner-wpp {
  text-decoration: none !important;
}
.banner-whatsapp {
  width: 100%;
  height: auto;
  margin-top: 57px;
  margin-bottom: 87px;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp {
    text-align: center;
    margin-top: 76px;
    margin-bottom: 0;
  }
}
.banner-whatsapp img {
  position: absolute;
  margin-top: -276px;
  z-index: 1;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp img {
    position: inherit;
    max-width: 298px;
    text-align: left;
    margin-top: -215px;
  }
}
.banner-whatsapp .infos {
  position: relative;
  z-index: 99;
  max-width: 409px;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp .infos {
    max-width: 298px;
    margin: 0 auto;
    padding-left: 14px;
    padding-right: 19px;
  }
}
@media screen and (min-width: 1025px) {
  .banner-whatsapp .infos {
    padding-left: 34px;
    padding-top: 32px;
  }
}
.banner-whatsapp .infos .title {
  display: block;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  color: #e30614;
  font-family: Zona Pro Bold;
  text-decoration: none !important;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp .infos .title {
    font-size: 24px;
    line-height: 28px;
    max-width: 227px;
    margin: 0 auto;
  }
}
.banner-whatsapp .infos p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  max-width: 295px;
  margin-top: 17px;
  margin-bottom: 18px;
  text-decoration: none !important;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp .infos p {
    font-size: 14px;
    line-height: 19px;
  }
}
.banner-whatsapp .infos .button {
  width: 257px;
  height: 49px;
  background: #00c855;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-decoration: none !important;
}
@media screen and (max-width: 1025px) {
  .banner-whatsapp .infos .button {
    width: 159px;
    margin-left: 6px;
  }
}
@media screen and (min-width: 1025px) {
  .banner-whatsapp .infos .button {
    border: 1px solid #707070;
  }
}
.banner-whatsapp .infos .button i {
  color: #fff;
  margin-right: 5px;
  font-size: 27.59px;
}
@media screen and (max-width: 1025px) {
  .blog-preview {
    margin-bottom: 5.5rem;
  }
}
@media screen and (min-width: 1025px) {
  .blog-preview {
    padding-top: 6rem;
  }
}
@media screen and (min-width: 1025px) {
  .blog-preview .generic-text {
    max-width: 90rem;
  }
}
@media screen and (max-width: 1025px) {
  .blog-preview .has-shaded-copy:before {
    max-width: 17rem;
  }
}
@media screen and (min-width: 1025px) {
  .blog-preview .has-shaded-copy:before {
    max-width: 35rem;
  }
}
.blog-preview .blog-post-instance {
  min-height: 42rem;
}
body .blog-post-instance {
  min-height: 43rem;
}
.blog-single {
  margin-bottom: 4rem;
}
.blog-post-title {
  color: #000;
  max-width: 21.5rem !important;
  min-height: 85px;
}
.blog-post-date,
.workplace-spot-date {
  max-width: 21.5rem;
  margin: 1rem auto 2.5rem auto;
}
@media screen and (max-width: 1025px) {
  .blog-post-date,
  .workplace-spot-date {
    padding-left: 2rem;
  }
}
.blog-post-date i,
.workplace-spot-date i {
  color: #c4161c;
  font-size: 1.6rem;
}
.blog-post-date span,
.workplace-spot-date span {
  margin-left: 0.3rem;
  margin-top: -0.2rem;
}
.wp-pagenavi {
  padding: 0.5rem;
  border: 0.2rem solid #6d6e71;
  font-size: 0;
  text-align: center;
}
.wp-pagenavi a,
.wp-pagenavi span {
  border: 0 !important;
  padding: 1rem;
  display: inline-block;
  vertical-align: top;
  font-size: 1.8rem;
  font-family: 'Zona Pro Regular';
  color: #333132;
  transition: all 0.2s ease-in-out;
}
.wp-pagenavi a + a,
.wp-pagenavi span + a,
.wp-pagenavi a span,
.wp-pagenavi span span {
  margin-left: 1rem;
}
.wp-pagenavi a:hover {
  background-color: #c4161c;
  color: #fff !important;
}
.work-props {
  position: relative;
}
.work-props .black {
  width: 213.2rem;
  height: 213.2rem;
  top: -103rem;
}
@media screen and (min-width: 1025px) and (max-width: 1700px) {
  .work-props .black {
    left: -248rem;
  }
}
@media screen and (min-width: 1700px) {
  .work-props .black {
    left: -236rem;
  }
}
.work-props .red {
  width: 23.6rem;
  height: 23.6rem;
  top: -46rem;
  left: -6rem;
}
@media screen and (max-width: 1025px) {
  .workplace-spots {
    margin-bottom: 10rem;
  }
}
@media screen and (min-width: 1025px) {
  .workplace-spots .workplace-spot-instance {
    min-height: 46rem;
  }
}
@media screen and (max-width: 1025px) {
  .workplace-spot-instance {
    margin-left: auto;
    margin-right: auto;
  }
}
.workplace-spot-title {
  font-family: 'Zona Pro Bold';
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  max-width: 21.8rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  min-height: 70px;
}
